<template>
  <v-sheet
    class="xflex xw-full md:xflex-row xflex-col xgap-[3px] xjustify-between xitems-center"
  >
    <div
      :class="[mdUp ? 'xflex-row' : 'xflex-col']"
      class="controller typer xflex xjustify-start xgap-[5px] xflex-1 xitems-center"
    >
      <v-select
        v-if="hasProject"
        dense
        :items="userProjects"
        item-text="name"
        item-value="id"
        outlined
        class="md:xmax-w-[300px] xmax-w-full"
        :value="selectedProject"
        @change="handleProjectChange"
        hide-details="auto"
        placeholder="Select Project"
      ></v-select>
      <KirbyDatePicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-if="type === 'daily'"
      ></KirbyDatePicker>
      <KirbyWeeklyPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'weekly'"
      ></KirbyWeeklyPicker>
      <KirbyMonthPicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'monthly'"
      ></KirbyMonthPicker>
      <KirbyRangePicker
        :value="selectedValue"
        no-future
        :class="[mdUp ? '' : 'xw-full']"
        @change="emitChange"
        v-else-if="type === 'custom'"
      ></KirbyRangePicker>
      <div
        :class="[mdUp ? '' : 'xw-full']"
        class="xflex xflex-row md:xjustify-start xjustify-center xitems-center xgap-[5px]"
      >
        <btn-tooltip
          tip="Filter by monthly"
          :color="type === 'monthly' ? '#7A38A3' : 'base_100'"
          :disabled="type === 'monthly'"
          :outlined="type !== 'monthly'"
          @click="setFilter('monthly')"
          class="text-none"
        >
          Month
        </btn-tooltip>
        <btn-tooltip
          tip="Custom range filter"
          :color="type === 'custom' ? '#7A38A3' : 'base_100'"
          :disabled="type === 'custom'"
          :outlined="type !== 'custom'"
          @click="setFilter('custom')"
          class="text-none"
        >
          Custom
        </btn-tooltip>
      </div>
    </div>
    <div
      :class="[mdUp ? 'xflex-row' : 'xflex-col xw-full']"
      class="xflex xgap-[0.5em] xjustify-center xitems-end"
    >
      <btn-tooltip
        tip="Filter by date"
        color="primary"
        :block="smDown"
        class="text-none"
        @click.stop="add_pto_modal = true"
      >
        <v-icon left>mdi-plus</v-icon> Add PTO
      </btn-tooltip>
      <btn-tooltip
        tip="Filter by date"
        color="primary"
        class="text-none"
        :block="smDown"
        @click.stop="add_time_modal = true"
      >
        <v-icon left>mdi-plus</v-icon> Add Time
      </btn-tooltip>

      <!-- <btn-export
        v-if="hasDownload"
        :api-csv="getFileUrl('csv')"
        :api-jpg="getFileUrl('jpg')"
        :api-pdf="getFileUrl('pdf')"
        :filename="fileName"
        :block="smDown"
      ></btn-export> -->

      <slot name="extra-buttons"></slot>
    </div>

    <AddTimeModal
      disable-select-user
      v-model="add_time_modal"
      :user="selectedUser"
    ></AddTimeModal>

    <AddPTOModal
      disable-select-user
      v-model="add_pto_modal"
      :user="selectedUser"
    ></AddPTOModal>
  </v-sheet>
</template>

<script>
import moment from "moment";
import KirbyWeeklyPicker from "@/views/GlobalComponents/Forms/KirbyWeeklyPicker.vue";
import KirbyDatePicker from "@/views/GlobalComponents/Forms/KirbyDatePicker.vue";
import KirbyMonthPicker from "@/views/GlobalComponents/Forms/KirbyMonthPicker.vue";
import KirbyRangePicker from "@/views/GlobalComponents/Forms/KirbyRangePicker.vue";
import AddPTOModal from "@/views/GlobalComponents/Modals/AddPTOModal.vue";
import AddTimeModal from "@/views/GlobalComponents/Modals/AddTimeModal.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    type: { type: String, default: "daily" },
    search: { type: String, default: undefined },
    value: { type: [Object, Array, String], default: undefined },
    hasDownload: { type: Boolean, default: true },
    hasProject: { type: Boolean, default: true },
    tab: { type: String, default: "time" },
  },
  data() {
    return {
      add_pto_modal: false,
      add_time_modal: false,
      selectedValue: null,
      selectedProject: null,
      selectedUser: null,
    };
  },
  watch: {
    value: {
      handler: function (val) {
        this.selectedValue = val;
      },
      immediate: true,
      deep: true,
    },
    selectedValue: {
      handler: function (val) {
        this.$emit("input", val);
      },
      immediate: true,
      deep: true,
    },
    "$route.params.pid": {
      handler: function (val) {
        this.selectedProject = val;
      },
      immediate: true,
    },
    "$route.params.user_id": {
      handler: function (val) {
        this.selectedUser = val;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("projects", ["userProjects"]),
    fileName() {
      if (this.type === "monthly")
        return `project-user-${this.selectedProject}-${this.selectedUser}-Monthly-${this.value}`;
      else
        return `project-user-${this.selectedProject}-${this.selectedUser}-Custom-${this.value[0]}-${this.value[1]}`;
    },
  },
  methods: {
    getFileUrl(file) {
      if (this.type === "monthly")
        return `api/project-users/${this.selectedProject}/${this.selectedUser}/monthly/${this.value}/null/${file}`;
      else
        return `api/project-users/${this.selectedProject}/${this.selectedUser}/custom/${this.value[0]}/${this.value[1]}/${file}`;
    },
    emitChange(val) {
      this.$emit("change", val);
    },
    emitSearch(val) {
      this.$emit("search", { keyword: val, value: this.value });
    },
    setFilter(type) {
      if (type === "monthly")
        this.$router
          .push({
            name: `app-user-${this.tab}-monthly`,
            params: {
              user_id: this.selectedUser,
              year_month: moment().format("YYYY-MM"),
              pid: this.selectedProject,
            },
          })
          .catch(() => {});
      else if (type === "custom")
        this.$router
          .push({
            name: `app-user-${this.tab}-custom`,
            params: {
              user_id: this.selectedUser,
              start: moment().startOf("week").format("YYYY-MM-DD"),
              end: moment().endOf("week").format("YYYY-MM-DD"),
              pid: this.selectedProject,
            },
          })
          .catch(() => {});
    },
    handleProjectChange(value) {
      this.selectedProject = value;
      this.setFilter(this.type);
    },
    gotTo(page) {
      if (page === "add-time")
        this.$router.push({
          name: "app-add-time",
          query: { user: this.selectedUser },
        });
      else if (page === "add-pto")
        this.$router.push({
          name: "app-add-pto",
          query: { user: this.selectedUser },
        });
    },
  },
  components: {
    KirbyWeeklyPicker,
    KirbyDatePicker,
    KirbyMonthPicker,
    KirbyRangePicker,
    AddPTOModal,
    AddTimeModal,
  },
};
</script>

<style lang="css">
.typer .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: #7a38a3 !important;
  color: #fff !important;
}
</style>
