var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-table',{attrs:{"loading":_vm.loading,"headings":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.navigateRecord(item)}}},[_vm._v(" View ")])]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("format")(item.date,"LL"))+" ")])]}},{key:"range",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("secToHuman")(item.duration_seconds || 0," hours", " minutes"))+" ")])]}},{key:"handler",fn:function(ref){
var item = ref.item;
return [(item.approved_by)?_c('avatar',{attrs:{"size":"35","user":item.approved_by,"with-name":""}}):_c('span')]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }