<template>
  <app-table :loading="loading" :headings="headers" :items="items">
    <template v-slot:actions="{ item }">
      <v-btn
        color="primary"
        small
        class="text-none"
        @click.stop="navigateRecord(item)"
      >
        View
      </v-btn>
    </template>
    <template v-slot:date="{ item }">
      <span>
        {{ item.date | format("LL") }}
      </span>
    </template>
    <template v-slot:range="{ item }">
      <span>
        {{ item.duration_seconds || 0 | secToHuman(" hours", " minutes") }}
      </span>
    </template>
    <!-- <template v-slot:status="{ item }">
      <v-chip small label outlined :color="item.approved_at ? 'success' : 'error'">
        {{ item.approved_at ? "Approved" : "Pending" }}
      </v-chip>
    </template> -->
    <template v-slot:handler="{ item }">
      <avatar
        size="35"
        v-if="item.approved_by"
        :user="item.approved_by"
        with-name
      ></avatar>
      <span v-else></span>
    </template>
  </app-table>
</template>

<script>
import moment from "moment";
export default {
  props: {
    loading: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      headers: [
        {
          text: "Date",
          value: "date",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Time IN",
          value: "time",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Duration",
          value: "range",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Description",
          value: "description",
          sortable: true,
          tdClass: "xtext-left",
        },
        {
          text: "Added By",
          value: "handler",
          sortable: true,
          tdClass: "xtext-left",
        }, 
        {
          text: "",
          value: "actions",
          sortable: false,
          tdClass: "xtext-right",
        },
      ],
    };
  },
  methods: {
    navigateRecord(item) {
      this.$router.push({
        name: "app-team-record-daily",
        params: { date: moment(item.start).format("YYYY-MM-DD") },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
